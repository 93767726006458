

export const Routes = {
    
    // pages
	Landing: { path: "/" },
	Login: { path: "/login" },
	ForgotPassword: { path: "/forgot-password" },
	ResetPassword: { path: "/reset-password/:register_token" },
	HelpMe: { path: "/help-me" },
	Dashboard: { path: "/dashboard" },
	Users: { path: "/users" },
	AddUser: { path: "/users/create" },
	Register: { path: "/register/:register_token" },
	Registration: { path: "/setup-password/:register_token" },
	Profile: { path: "/users/profile" },
	SavingCommitment: { path: "/saving_commitment" },
    SecurityQuestions: { path: "/security_questions" },
	CollectionMonthRequest: { path: "/collection_month_request" },
	ViewCollectionMonthRequest: { path: "/collection_month_request/:id" },
	SavingCommitmentSavers: { path: "/saving_commitments" },
    Presentation: { path: "/documentation/overview" },
    DashboardOverview: { path: "/dashboard/overview" },
    Transactions: { path: "/transactions" },
    Settings: { path: "/settings" },
    Upgrade: { path: "/upgrade" },
	Invoice: { path: "/examples/invoice" },
	CommitmentMonth: { path: "/commitment_month" },
	EmailTemplate: { path: "/email_template" },
	Notification: { path: "/notification" },
    NotFound: { path: "/examples/404" },
	Membership: { path: "/membership-agreement" },
	Fee: { path: "/fee-schedule" },
	DeletedUsers: { path: "/deleted_records" },
	WritingUsers: { path: "/writing_user" },
	MemberReport: { path: "/member-report" },
	SavingCommitmentReport: { path: "/saving-commitment-report" },

};