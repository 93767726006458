import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';

import { Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Modal, Row, Col, Form } from '@themesberg/react-bootstrap';

import { Link, useHistory } from 'react-router-dom';

import Preloader from "../components/Preloader";

import Loader from "../components/Loader";



import { Routes } from "../routes";

import transactions from "../data/transactions";

import axios from 'axios';

import Swal from 'sweetalert2';



const ValueChange = ({ value, suffix }) => {

	const valueIcon = value < 0 ? faAngleDown : faAngleUp;

	const valueTxtColor = value < 0 ? "text-danger" : "text-success";



	return (

		value ? <span className={valueTxtColor}>

			<FontAwesomeIcon icon={valueIcon} />

			<span className="fw-bold ms-1">

				{Math.abs(value)}{suffix}

			</span>

		</span> : "--"

	);

};







export const MonthRequestTable = (searchKey) => {



	const [showDefault, setShowDefault] = useState(false);

	const handleClose = () => setShowDefault(false);



	const [data, setData] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);

	const [startPage, setStartPage] = useState(1);

	const [endPage, setEndPage] = useState(1);

	const [totalRecord, setTotalRecord] = useState(1);

	const [perPage, setPerPage] = useState(1);

	const [totalPages, setTotalPages] = useState(1);

	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(true);
	
	
	const [currentSort, setCurrentSort] = useState("id");
	
	const [currentDirection, setCurrentDirection] = useState("desc");



	const [showCommitmentDefault, setShowCommitmentDefault] = useState(false);

	const [commitmentData, setCommitmentData] = useState([]);

	const handleCloseCommitment = () => setShowCommitmentDefault(false);



	const history = useHistory();



	const storedUser = localStorage.getItem('user');

	const userObject = JSON.parse(storedUser);

	const accessToken = userObject.access_token;

	const user_id = userObject.user_id;

	const role_id = userObject.role_id;

	const approval_request_permission = userObject.approval_request_permission;





	{/*Sorting*/ }

	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });





	const handleSort = (key) => {

		let direction = 'asc';

		if (sortConfig.key === key && sortConfig.direction === 'asc') {

			direction = 'desc';

		}

		setSortConfig({ key, direction });
		
		
		setCurrentSort(key);
		
		setCurrentDirection(direction);



		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;

		const user_id = userObject.user_id;



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/collection_month_request';



		const formData = new FormData();

		formData.append('page', 1);

		formData.append('search', search);

		formData.append('sortBy', key);

		formData.append('orderBy', direction);

		if (role_id != 1) {

			formData.append('user_id', user_id);

		} else {

			formData.append('user_id', "");

		}





		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};





	const getSortIcon = (columnKey) => {

		if (sortConfig.key === columnKey) {

			return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;

		}

		return <FontAwesomeIcon icon={faSort} />;

	};



	{/*End Sorting*/ }







	const fetchData = async page => {



		if (role_id === 1 && approval_request_permission !== "1") {

			history.push('/dashboard');

			return false;

		}



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/collection_month_request';



		const formData = new FormData();

		formData.append('page', page);

		formData.append('search', search);
		
		formData.append('sortBy', currentSort);
		
		formData.append('orderBy', currentDirection);

		if (role_id != 1) {

			formData.append('user_id', user_id);

		} else {

			formData.append('user_id', "");

		}



		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};






	useEffect(() => {

		setSearch(searchKey?.data);

	}, [searchKey?.data]);

	useEffect(() => {
		fetchData(currentPage);
	}, [search]);





	const handlePageChange = page => {

		setCurrentPage(page);

	};



	const onPageChange = (pageNo) => {

		setCurrentPage(pageNo);

		fetchData(pageNo);

	};





	const handleStatus = (id, actionType) => {

		const formData1 = new FormData();

		formData1.append('requested_id', id);

		formData1.append('status', actionType);





		let title = "";

		let textMsg = "";

		if (actionType === "1") {

			formData1.append('reject_reason', "");



			title = "Approve Request";

			textMsg = "Are you sure you want to approve this Request?";



			Swal.fire({

				title: title,

				text: textMsg,

				icon: 'warning',

				showCancelButton: true,

				confirmButtonText: 'Confirm',

				cancelButtonText: 'Cancel',

				confirmButtonColor: '#262B40',

			}).then((result) => {

				if (result.isConfirmed) {

					const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/request_collection_month_status_update';

					axios.post(apiBaseURL, formData1, {

						headers: {

							Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

						}

					})

						.then(({ data }) => {

							if (data.status === "success") {

								setShowDefault(false);

								Swal.fire('Request has been approved successfully.', '', 'success').then(() => {

									window.location.reload();

								})

							}

							//setLoading(false);

						}).catch(error => {

							//setLoading(false);

						});

				}

			});



		} else {

			title = "Reject Request";

			textMsg = "Are you sure you want to reject this Request?";

			const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/request_collection_month_status_update';



			Swal.fire({

				title: title,

				text: textMsg,

				icon: 'warning',

				showCancelButton: true,

				confirmButtonText: 'Confirm',

				cancelButtonText: 'Cancel',

				confirmButtonColor: '#262B40',

			}).then((result) => {

				if (result.isConfirmed) {



					Swal.fire({

						title: 'Reject',

						input: 'text',

						inputPlaceholder: 'Enter rejected reason',

						showCancelButton: true,

						confirmButtonText: 'Confirm',

						showLoaderOnConfirm: true,

						preConfirm: (reason) => {

							formData1.append('reject_reason', reason);



							axios.post(apiBaseURL, formData1, {

								headers: {

									Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

								}

							})

								.then(({ data }) => {

									if (data.status === "success") {

										setShowDefault(false);



										Swal.fire('Request has been rejected successfully.', '', 'success').then(() => {

											window.location.reload();

										})

									}

									//setLoading(false);

								}).catch(error => {

									//setLoading(false);

								});

						},

					});



				}

			});



		}

	};









	const viewDetails = (id) => {

		history.push(`/collection_month_request/${id}`);

		/*

		setShowCommitmentDefault(true);

		const formData1 = new FormData();

		formData1.append('requested_id', id);

		

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/user_commitment_details';  	 

		axios.post(apiBaseURL, formData1, {

		  headers: {

			Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

		  }

		})

		.then(({ data }) => {

			if(data.status==="success"){

			 setCommitmentData(data.data.data);

			}

			setLoading(false);

		}).catch(error => {

			setLoading(false);

		});

		*/

	}



	if (loading) {

		//return <div>Loading...</div>;

	}



	const items = [];

	for (let i = 0; i < totalPages; i++) {

		items.push(i + 1);

	}





	const trimText = (text, maxLength) => {

		if (text.length > maxLength) {

			return text.substring(0, maxLength) + '..';

		}

		return text;

	};



	const totalUsers = data.length;



	const srStart = (currentPage - 1) * perPage;



	const modifiedData = data.map((t, index) => ({

		...t,

		serialNumber: srStart + index + 1 // Assuming the serial number starts from 1

	}));



	const prevPage = currentPage > 1 ? currentPage - 1 : 1;

	const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;





	const TableRow = (props) => {

		const { id, savers_name, saving_goal, amount, serialNumber, month_name, reason, created_at, status, user_id } = props;

		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;

		const history = useHistory();

		// Convert created_at to a Date object

		const createdAtDate = new Date(created_at.replace(/\s/, 'T'));

		// Format created_at as DD-MM-YYYY (for example)

		const formattedCreatedAt = `${createdAtDate.getFullYear()}-${String(createdAtDate.getMonth() + 1).padStart(2, '0')}-${String(createdAtDate.getDate()).padStart(2, '0')} ${String(createdAtDate.getHours()).padStart(2, '0')}:${String(createdAtDate.getMinutes()).padStart(2, '0')}:${String(createdAtDate.getSeconds()).padStart(2, '0')}`;



		const maxLength = 10;

		const trimmedReason = trimText(reason, maxLength);





		return (

			<tr>
				<td>
					<span className="fw-normal">
						{serialNumber}
					</span>
				</td>

				<td>
					<span className="fw-normal">
					{role_id == "1" ? (
						<a href={`users?user=${user_id}`}>
							{savers_name}
						</a>
					) : (
						savers_name
					)}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{saving_goal}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						£{amount}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{month_name}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						 {status === "0" ? "Pending" : status === "1" ? "Approved" : status === "2" ? "Rejected" : "Unknown"}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{formattedCreatedAt}
					</span>

				</td>
				
				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
							</span>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item className="text-primary" onClick={() => viewDetails(id)}>
							<FontAwesomeIcon icon={faEye} className="me-2" title="View" />View
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</td>
			</tr>

		);

	};



	return (

		<>

			{loading && <Preloader show={true} />}

			<Card border="light" className="table-wrapper table-responsive shadow-sm">

				<Card.Body className="pt-0">

					<Table hover className="user-table align-items-center">

						<thead>

							<tr>

								<th className="border-bottom notsort" onClick={() => handleSort('collection_month_request.id')}>Request ID {getSortIcon('collection_month_request.id')}</th>

								<th className="border-bottom">Saver Name</th>

								<th className="border-bottom" onClick={() => handleSort('saving_commitment_list.name')}>Savings Goal {getSortIcon('saving_commitment_list.name')}</th>

								<th className="border-bottom" onClick={() => handleSort('collection_month_request.amount')}>Amount {getSortIcon('collection_month_request.amount')}</th>

								<th className="border-bottom" onClick={() => handleSort('commitment_months.month_name')}>Requested Month {getSortIcon('commitment_months.month_name')}</th>

								<th className="border-bottom" onClick={() => handleSort('collection_month_request.reason')}>Status {getSortIcon('collection_month_request.reason')}</th>

								<th className="border-bottom" onClick={() => handleSort('collection_month_request.created_at')}>Request Date {getSortIcon('collection_month_request.created_at')}</th>
								<th className="border-bottom">Action</th>
							</tr>

						</thead>

						<tbody>
							
							{modifiedData.length === 0 ? (
							<tr>
								  <td colSpan="8" className="text-center">No Record Found</td>
								</tr>
							 ) : (
								modifiedData.map(t => <TableRow key={`data-${t.id}`} {...t} />)
							 )}

						</tbody>

					</Table>

					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">

						<Nav>

							<Pagination className="mb-2 mb-lg-0">

								<Pagination.Prev onClick={() => onPageChange(prevPage)}>

									Previous

								</Pagination.Prev>



								{items.map((item, index) => (

									<Pagination.Item active={item === currentPage} onClick={() => onPageChange(item)}>{item}</Pagination.Item>

								))}



								<Pagination.Next onClick={() => onPageChange(nextPage)}>

									Next

								</Pagination.Next>

							</Pagination>

						</Nav>

						<small className="fw-bold">

							Showing <b>{totalUsers}</b> out of <b>{totalRecord}</b> entries

						</small>

					</Card.Footer>

				</Card.Body>

			</Card>



			<React.Fragment>

				<Modal as={Modal.Dialog} centered show={showCommitmentDefault} onHide={handleCloseCommitment}>

					<Modal.Header>

						<Modal.Title className="h6">Commitment Details</Modal.Title>

						<Button variant="close" aria-label="Close" onClick={handleCloseCommitment} />

					</Modal.Header>



					<Modal.Body>

						<Row>

							<Col md={12}>

								<Col md={9} style={{ float: 'right' }}>

									<p>Amit</p>

								</Col>

								<Col md={3}>

									<b>Saver Name :</b>

								</Col>

							</Col>

						</Row>

					</Modal.Body>



				</Modal>

			</React.Fragment>

		</>

	);

};