import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Alert, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import BgImage from "../assets/img/illustrations/login_img.jpg";
import logoImage from "../assets/img/illustrations/logo.png";
import axios from 'axios';
import { useHistory, Link } from 'react-router-dom';
import Loader from "../components/Loader";
import { Routes } from "../routes";

export default () => {

	const [loading, setLoading] = useState(false);

	const [section, setSection] = useState("help");

	const [formData, setFormData] = useState({
		email: '',
		password: '',
	});
	const [errors, setErrors] = useState({
		email: '',
		password: '',
	});

	const [invalid, setInvalid] = useState(false);
	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData({
			...formData,
			[name]: value,
		});
	};
	const history = useHistory();
	const handleSubmit = (e) => { };
	const divStyle = {
		height: '100%', // Note: Make sure to enclose 100% in quotes

		left: 0,
		top: 0,
		overflow: 'hidden', // Note: Make sure to enclose 'hidden' in quotes
		position: 'fixed'   // Note: Make sure to enclose 'fixed' in quotes
	};

	const btnWidth = {
		width: '300px'
	};

	const storedUser = localStorage.getItem('user');
	if (storedUser) {
		console.log('data found');
		history.push('/dashboard');
	} else {
		console.log('data Not found');
		//history.push('/');
	}
	return (
		<>
			{loading && <Loader />}
			<main>
				<section className="d-flex align-items-center p-0">
					<Container fluid className="p-0">
						<Row className="m-0 user-area-section" style={divStyle}>
							<Col md={8} className="p-0">
								<div className="form-bg-image" style={{ height: '100%' }}>
									<img src={BgImage} alt="Background" className="w-100 h-100" />
								</div>
							</Col>

							<Col md={4} className="bg-white">
								<div className="login-block">

									{section === "help" && (
										<div className="bg-white p-0 p-lg-4 mg-b">
											<div className="text-md mb-4 mt-md-0">
												<h5 className="mb-0">What can we help you with?</h5>
											</div>
											<p>Please make a selection from the list</p>
											<Form className="mt-4" onSubmit={handleSubmit}>

												<Link to={Routes.ForgotPassword.path}>
													<Button variant="primary" type="submit" className="btn btn-secondry" style={btnWidth}>
														I forgot my password
													</Button>
												</Link><br /><br />

												<Button variant="primary" type="submit" className="btn btn-secondry" onClick={() => setSection('password-not-working')} style={btnWidth}>
													My password is not working
												</Button><br /><br />

												<Button variant="primary" type="button" className="btn btn-secondry" onClick={() => setSection('not-account')} style={btnWidth}>
													I do not have a Savvey Savers Network account but need assistance
												</Button>

											</Form>
											<div className="bg-white p-0 pt-4">
												<span className="mt-4 Copyright">Copyright @ 2025 Savvey Savers Network</span>
											</div>
										</div>
									)}


									{section === "password-not-working" && (
										<div className="bg-white p-0 p-lg-4 mg-b">
											<div className="text-md mb-4 mt-md-0">
												<h5 className="mb-0">What can we help you with?</h5>
											</div>
											<p>My password is not working</p>

											<Form className="mt-4">
												<p>Did You Know?</p>
												<p>If you recently changed your password and are having trouble with it, your browser may have saved your old password.</p>
												<p>Try clearing any password you may have saved on your browser for your Savvey Savers account and re-entering the new one</p>
												<p>If you recently changed your password and can’t remember your password, please visit our <Link to="/forgot-password">Password reset page</Link> and create a new password.</p>
												<p>If you have followed all the steps above and are still not able to log in, please contact us via email at <a href="mailto:Help@savveysavers.com">Help@savveysavers.com</a></p>
											</Form>
											<Link to="/login"><Button variant="primary" type="submit" className="btn btn-secondry mb-2" >

												Return to Login Page

											</Button></Link>
										</div>
									)}

									{section === "not-account" && (
										<div className="bg-white p-0 p-lg-4 mg-b">
											<div className="text-md mb-4 mt-md-0">
												<h5 className="mb-0">What can we help you with?</h5>
											</div>
											<p>I do not have a Savvey Savers Network account but need assistance</p>

											<Form className="mt-4">
												<p>Did You Know?</p>
												<p>To use the Savvey Savers platform, you have to be a Network Member. If you would like more details about how to become a member, please contact us via email at <a href="mailto:Help@savveysavers.com">Help@savveysavers.com</a></p>
												<p>Please include your name, contact information and reason for your query in the email.</p>
											</Form>
											<Link to="/login"><Button variant="primary" type="submit" className="btn btn-secondry mb-2" >

												Return to Login Page

											</Button></Link>
										</div>
									)}

								</div>
							</Col>

						</Row>
					</Container>
				</section>
			</main >
		</>
	);
};