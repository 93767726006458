

import React, { useState , useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEnvelope, faUnlockAlt, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Alert, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';



import BgImage from "../assets/img/illustrations/login_img.webp";

import logoImage from "../assets/img/illustrations/logo.png";

import axios from 'axios';

import { Routes } from "../routes";

import { Link, useHistory } from 'react-router-dom';

import Loader from "../components/Loader";







export default () => {



  const [loading, setLoading] = useState(false);

  const btnWidth = {

    width: '180px'

  };

  const [formData, setFormData] = useState({

    email: '',

    password: '',

  });



  const [errors, setErrors] = useState({

    email: '',

    password: '',

  });


  const [showPassword, setShowPassword] = useState(false);


  const [invalid, setInvalid] = useState(false);
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
	if(!showPassword){
		const timer = setTimeout(() => {
		  setShowPassword(showPassword);
		}, 5000);
	}
  };



  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setFormData({

      ...formData,

      [name]: value,

    });

  };

  const history = useHistory();



  const handleSubmit = (e) => {

    e.preventDefault();

    // Validate email and password

    let valid = true;

    const newErrors = {

      email: '',

      password: '',

    };



    if (!formData.email || !formData.email.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/)) {

      newErrors.email = 'Please enter a valid email address';

      valid = false;

    }



    if (formData.email === "") {

      newErrors.email = 'Email field is required';

      valid = false;

    }



    if (!formData.password || formData.password.length < 6) {

      newErrors.password = 'Password must be at least 6 characters long';

      valid = false;

    }



    if (formData.password === "") {

      newErrors.password = 'Password field is required';

      valid = false;

    }



    setErrors(newErrors);



    if (valid) {

      setLoading(true);

      // Read the environment variable

      const apiBaseURL = process.env.REACT_APP_API_BASE_URL;



      // Perform login logic here, for example, sending data to an API

      axios.defaults.baseURL = apiBaseURL;

      axios.post('/login', formData)

        .then(({ data }) => {

          if (data.status === "success") {

            setLoading(false);

            // Save user data in localStorage

            localStorage.setItem('user', JSON.stringify(data.content));



            // Redirect to another route (e.g., dashboard)

            if ((data.content.is_saving_commitment === "0") && (data.content.role_id != 1)) {

              history.push('/saving_commitments');

            } else {

              history.push('/dashboard');

            }

          } else {

            setLoading(false);

            setInvalid(true);

          }

        }).catch(error => {

          setLoading(false);

          setInvalid(true);

        });

    }

  };

  const divStyle = {

    height: '100%', // Note: Make sure to enclose 100% in quotes

    left: 0,

    top: 0,

    overflow: 'hidden', // Note: Make sure to enclose 'hidden' in quotes

    position: 'fixed'   // Note: Make sure to enclose 'fixed' in quotes

  };

  const storedUser = localStorage.getItem('user');

  if (storedUser) {

    console.log('data found');

    history.push('/dashboard');

  } else {

    console.log('data Not found');

    //history.push('/');

  }

  return (

    <>

      {loading  ? <Loader /> :

      <main>

        <section className="d-flex align-items-center p-0">

          <Container fluid className="p-0">

            <Row className="m-0 user-area-section" style={divStyle}>

              <Col md={8} className="p-0">

                <div className="form-bg-image" style={{ height: '100%' }}>

                  <img src={BgImage} alt="Background" className="w-100 h-100" />

                </div>

              </Col>

              <Col md={4} className="bg-white">

                <div className="login-block">

                  <div className="bg-white p-0 p-lg-4 mg-b">

                    <div className="text-center text-md-center mb-4 mt-md-0">

                      <img src={logoImage} alt="Background" style={{ height: '100px' }} />

                      <h5 className="mb-0">Savvey Savers Networks</h5>

                    </div>

                    {invalid &&

                      <Alert variant="danger">

                        please enter a valid user name and/or password to login

                      </Alert>

                    }

                    <Form className="mt-4" onSubmit={handleSubmit}>

                      <Form.Group id="email" className="mb-4">

                        <Form.Label>Email</Form.Label>

                        <InputGroup>

                          <InputGroup.Text>

                            <FontAwesomeIcon icon={faEnvelope} />

                          </InputGroup.Text>

                          <Form.Control autoFocus name="email" type="email" placeholder="" className={errors.email ? 'error' : ''} value={formData.email} onChange={handleInputChange} />

                        </InputGroup>

                        <div className="error-message">{errors.email}</div>

                      </Form.Group>

                      <Form.Group>

                        <Form.Group id="password" className="mb-4">

                          <Form.Label>Password</Form.Label>

                          <InputGroup>

                            <InputGroup.Text>

                              <FontAwesomeIcon icon={faUnlockAlt} />

                            </InputGroup.Text>

                            <Form.Control name="password" type={showPassword ? 'text' : 'password'} placeholder="" value={formData.password} onChange={handleInputChange} className={errors.password ? 'error' : ''} />
							<Button variant="light" onClick={togglePasswordVisibility}>
								{showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
							</Button>
                          </InputGroup>

                          <div className="error-message">{errors.password}</div>

                        </Form.Group>

                        <div className="d-flex justify-content-between align-items-center mb-4">

                          <Form.Check type="checkbox">

                            <FormCheck.Input id="defaultCheck5" className="me-2" />

                            <FormCheck.Label htmlFor="defaultCheck5" className="mb-0">Remember me</FormCheck.Label>

                          </Form.Check>


                        </div>

                      </Form.Group>

                      <div className="bg-white p-0 forgot-row">
                        <Button variant="primary" type="submit" className="btn btn-secondry center loginBtn">

                          Sign in

                        </Button>
                      </div>
					  <br/>
					  <div className="bg-white p-0 forgot-row">
						<Link className="btn btn-secondry center forgot-link loginBtn"  to={Routes.ForgotPassword.path}>
                              Forgot password?
						</Link>
					</div>
					  <br/>
					  <Link to={Routes.HelpMe.path}>
                        <Button variant="primary" type="button" className="btn btn-secondry loginBtn">
                          Help, I Can't log in
                        </Button>
                      </Link>

                    </Form>

                    <div className="bg-white p-0 pt-2 mt-5 center">

                      <span class="mt-4 Copyright">Copyright @ 2025 Savvey Savers Network</span>

                    </div>

                  </div>

                </div>

              </Col>

            </Row>

          </Container>

        </section>

      </main> }

    </>

  );

};

