import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { Nav, Card, Button, Table, Form, Alert, FormCheck, Dropdown, Pagination, ButtonGroup, Col, Row, Modal } from '@themesberg/react-bootstrap';

import { Link, useHistory } from 'react-router-dom';

import Preloader from "../components/Preloader";

import Loader from "../components/Loader";



import { Routes } from "../routes";

import transactions from "../data/transactions";

import axios from 'axios';

import Swal from 'sweetalert2';




export const DeletedBlankTable = (searchKey) => {


	const TableRow = (props) => {

		return (

			<tr>
				<td>
				</td>
			</tr>

		);

	};

	return (
		<>
			<Card border="light" className="table-wrapper table-responsive shadow-sm">
				<Card.Body className="pt-0">
					<Table hover className="user-table align-items-center center mt-3">
						<thead>
							<tr>
								No records found. Please select deleted record type.
							</tr>
						</thead>
					</Table>
				</Card.Body>
			</Card>
		</>

	);

};