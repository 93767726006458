import React, { useState, useEffect, useMemo } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt, faRuler, faReceipt, faFileInvoice } from '@fortawesome/free-solid-svg-icons';

import { Nav, Card, Button, Table, Dropdown, Pagination, ButtonGroup, Modal, Row, Col } from '@themesberg/react-bootstrap';

import { Link } from 'react-router-dom';

import Preloader from "../components/Preloader";

import Loader from "../components/Loader";



import { Routes } from "../routes";

import transactions from "../data/transactions";

import axios from 'axios';

import Swal from 'sweetalert2';



const ValueChange = ({ value, suffix }) => {

	const valueIcon = value < 0 ? faAngleDown : faAngleUp;

	const valueTxtColor = value < 0 ? "text-danger" : "text-success";



	return (

		value ? <span className={valueTxtColor}>

			<FontAwesomeIcon icon={valueIcon} />

			<span className="fw-bold ms-1">

				{Math.abs(value)}{suffix}

			</span>

		</span> : "--"

	);

};







export const SavingCommitmentSaversTable = (searchKey, savingYear) => {



	const [showDefault, setShowDefault] = useState(false);

	const handleClose = () => setShowDefault(false);



	const [data, setData] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);

	const [startPage, setStartPage] = useState(1);

	const [endPage, setEndPage] = useState(1);

	const [totalRecord, setTotalRecord] = useState(1);
	
	const [currentSort, setCurrentSort] = useState("id");
	
	const [currentDirection, setCurrentDirection] = useState("desc");

	const [perPage, setPerPage] = useState(1);

	const [totalPages, setTotalPages] = useState(1);

	const [search, setSearch] = useState("");

	const [loading, setLoading] = useState(true);

	const [savingYears, setSavingYears] = useState("");


	const [showCommitmentDefault, setShowCommitmentDefault] = useState(false);

	const [commitmentData, setCommitmentData] = useState([]);
	const [paymentHistoryData, setPaymentHistoryData] = useState([]);

	const handleCloseCommitment = () => setShowCommitmentDefault(false);



	const storedUser = localStorage.getItem('user');

	const userObject = JSON.parse(storedUser);

	const accessToken = userObject.access_token;

	const user_id = userObject.user_id;





	{/*Sorting*/ }

	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });



	const handleSort = (key) => {

		let direction = 'asc';

		if (sortConfig.key === key && sortConfig.direction === 'asc') {

			direction = 'desc';

		}

		setSortConfig({ key, direction });



		console.log("key", key);

		console.log("direction", direction);
		
		setCurrentSort(key);
		
		setCurrentDirection(direction);



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/saving_commitment/' + user_id;



		const formData = new FormData();

		formData.append('page', 1);

		formData.append('search', search);

		formData.append('sortBy', key);

		formData.append('orderBy', direction);

		formData.append('savingYears', savingYears);

		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorizations: accessToken // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};



	const getSortIcon = (columnKey) => {

		if (sortConfig.key === columnKey) {

			return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;

		}

		return <FontAwesomeIcon icon={faSort} />;

	};

	{/*End Sorting*/ }





	const fetchData = async page => {



		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/saving_commitment/' + user_id;



		const formData = new FormData();

		formData.append('page', page);

		formData.append('search', search);

		formData.append('sortBy', currentSort);
		
		formData.append('orderBy', currentDirection);

		formData.append('savingYears', savingYears);

		setLoading(true);



		axios.post(apiBaseURL, formData, {

			headers: {

				Authorizations: accessToken // Include the access token in the Authorization header

			}

		})

			.then(({ data }) => {

				if (data.status === "success") {

					setData(data.data.data);

					setCurrentPage(data.data.current_page);

					setTotalRecord(data.data.total);

					setPerPage(data.data.per_page);

					setStartPage(data.data.per_page);

					setEndPage(data.data.last_page);



					var totalPages = Math.ceil(data.data.total / data.data.per_page);

					setTotalPages(totalPages);



				}

				setLoading(false);

			}).catch(error => {

				setLoading(false);

			});



	};






	useEffect(() => {

		setSearch(searchKey?.data);

	}, [searchKey?.data]);
	
	useEffect(() => {

		setSavingYears(searchKey?.savingYear);

	}, [searchKey?.savingYear]);
	

	useEffect(() => {
		fetchData(currentPage);
	}, [search]);
	
	useEffect(() => {
		fetchData(currentPage);
	}, [savingYears])





	const handlePageChange = page => {

		setCurrentPage(page);

	};



	const onPageChange = (pageNo) => {

		setCurrentPage(pageNo);

		fetchData(pageNo);

	};





	const onViewData = (id, amount, saving_goal, collection_month, serialNumber, savers_name, formattedEndDate) => {


		{/*
		let datas = { "id": id, "amount": amount, "saving_goal": saving_goal, "collection_month": collection_month, "serialNumber": serialNumber, "savers_name": savers_name, "formattedEndDate": formattedEndDate }

		console.log("datas", datas);

		setCommitmentData(datas);

		setPaymentHistoryData(data.data);

		setShowCommitmentDefault(true);
		*/}
		
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/payment/history';

		const formDataPayment = new FormData();
		formDataPayment.append('user_saving_id', id);

		axios.post(apiBaseURL, formDataPayment, {
			headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
			}
		}).then(({ data }) => {
				
				if (data.status === "success") {
					setLoading(false);
					
					let datas = { "id": id, "amount": amount, "saving_goal": saving_goal, "collection_month": collection_month, "serialNumber": serialNumber, "savers_name": savers_name, "formattedEndDate": formattedEndDate }
					setCommitmentData(datas);
					setPaymentHistoryData(data.data);
					setShowCommitmentDefault(true);
					
				}
				setLoading(false);

		}).catch(error => {
			setLoading(false);
		});



		/*

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/saving_commitment_details/'+id;  

						 

		const formData = new FormData();

		setLoading(true);

						 

		axios.post(apiBaseURL, formData, {

		  headers: {

			Authorizations: accessToken // Include the access token in the Authorization header

		  }

		})

		.then(({ data }) => {

			if(data.status==="success"){

			  setData(data.data.data); 

			}

			setLoading(false);

		}).catch(error => {

			setLoading(false);

		});

		*/

	};







	{/*...........................Confirm Harvest Payment....................*/ }

	const confirmHarvestPayment = (props) => {

		console.log("props", props)

		const formData1 = new FormData();

		formData1.append('requested_id', props.id);



		Swal.fire({

			title: "Confirm Receipt of Savings Harvest",

			text: "Are you sure you want to confirm receipt of your savings harvest in your bank account?",

			icon: 'warning',

			showCancelButton: true,

			confirmButtonText: 'Confirm',

			cancelButtonText: 'Cancel',

			confirmButtonColor: '#262B40',

		}).then((result) => {
			
			setLoading(true);

			if (result.isConfirmed) {

				const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/confirm_harvest_release';

				axios.post(apiBaseURL, formData1, {

					headers: {

						Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header

					}

				})

					.then(({ data }) => {

						if (data.status === "success") {

							setShowDefault(false);

							Swal.fire('Thanks for confirming receipt of your Savings Harvest in your nominated bank account.', '', 'success').then(() => {

								window.location.reload();

							})

						}

						setLoading(false);

					}).catch(error => {

						setLoading(false);

					});

			}

		});



	};

	{/*.....................End Confirm Harvest payment......................*/ }







	if (loading) {

		//return <div>Loading...</div>;

	}



	const items = [];

	for (let i = 0; i < totalPages; i++) {

		items.push(i + 1);

	}





	const totalUsers = data.length;



	const srStart = (currentPage - 1) * perPage;



	const modifiedData = data.map((t, index) => ({

		...t,

		serialNumber: srStart + index + 1 // Assuming the serial number starts from 1

	}));



	const prevPage = currentPage > 1 ? currentPage - 1 : 1;

	const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;





	const TableRow = (props) => {

		const { id, amount, saving_goal, commitment_details, collection_month, serialNumber, savers_name, created_at, status } = props;





		// Convert created_at to a Date object

		const createdAtDate = new Date(created_at);

		const startDate = new Date(created_at);

		let endMonth = startDate.getMonth();

		let endYear = startDate.getFullYear();

		const monthNames = [

			'January', 'February', 'March', 'April', 'May', 'June',

			'July', 'August', 'September', 'October', 'November', 'December'

		];



		// Format created_at as DD-MM-YYYY (for example)

		const options = { year: 'numeric', month: 'long' };

		//const formattedCreatedAt = createdAtDate.toLocaleDateString(undefined, options);



		const formattedCreatedAt = collection_month + ` ${endYear}`;



		function YourComponent({ created_at }) {

			const formattedCreatedAt = useMemo(() => {

				const createdAtDate = new Date(created_at);

				const options = { year: 'numeric', month: 'long' };

				return createdAtDate.toLocaleDateString(undefined, options);

			}, [created_at]);



			return <div>{formattedCreatedAt}</div>;

		}



		// Get end date by adding one year to created_at

		const endDate = new Date(createdAtDate);

		endDate.setFullYear(endDate.getFullYear() + 1);



		// Format end date as DD-MM-YYYY (for example)

		//const formattedEndDate = `${endDate.getDate()}-${endDate.getMonth() + 1}-${endDate.getFullYear()}`;

		const formattedEndDate = `December ${endYear}`;

		if(id === 55){
			console.log(props)
		}
		
		
		
		// Convert created_at to Date object
		const currentDate = new Date();
		const currentYear = currentDate.getFullYear();
		const currentMonth = currentDate.getMonth();
		
		let computedStatus;
		
		if (status === 0) {
			computedStatus = "Cancelled";
		} else {
			if (endYear > currentYear || (endYear === currentYear && endMonth > currentMonth)) {
				computedStatus = "Not Started";
			} else if (endYear === currentYear && endMonth <= currentMonth) {
				computedStatus = "Active";
			} else if (endYear < currentYear) {
				computedStatus = "Completed";
			} else {
				computedStatus = "Active";
			}
		}

		return (

			<tr>

				<td>
					<span className="fw-normal" style={{cursor:'pointer'}} onClick={() => onViewData(id, amount, saving_goal, collection_month, serialNumber, savers_name, formattedEndDate)}>
						SC-00{id}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{savers_name}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{"£" + amount}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{formattedCreatedAt}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{formattedEndDate}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						<button type="button" className="m-1 btn btn-primary btn-sm saving_status">{computedStatus}</button>
					</span>
				</td>

				<td>
					<Dropdown as={ButtonGroup}>
						<Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
							<span className="icon icon-sm">
								<FontAwesomeIcon icon={faEllipsisH} className="icon-dark" />
							</span>
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.Item className="text-primary" onClick={() => onViewData(id, amount, saving_goal, collection_month, serialNumber, savers_name, formattedEndDate)}>

								<FontAwesomeIcon icon={faEye} className="me-2" /> View

							</Dropdown.Item>
							
							{props.is_payment_released === "1" && props.is_payment_confirmed === "0" ? (
								<Dropdown.Item className="text-primary" onClick={() => confirmHarvestPayment(props)}>
									<FontAwesomeIcon icon={faFileInvoice} className="me-2 ms-1" title="Confirm Harvest Payment" /> Confirm Harvest Payment
								</Dropdown.Item>
							) : (
								<Dropdown.Item className="text-primary">
									<FontAwesomeIcon icon={faFileInvoice} className="me-2 ms-1" title="Harvest" style={{ color: 'grey' }}/> Harvest
								</Dropdown.Item>
							)}
							
						</Dropdown.Menu>
					</Dropdown>
				</td>

			</tr>

		);

	};



	return (

		<>

			{loading && <Preloader show={true} />}

			<Card border="light" className="table-wrapper table-responsive shadow-sm">

				<Card.Body className="pt-0">

					<Table hover className="user-table align-items-center">

						<thead>

							<tr>

								<th className="border-bottom notsort" onClick={() => handleSort('id')}>

									Record Id {getSortIcon('id')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('memberName')}>

									Member Name {getSortIcon('memberName')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('savingsAmount')}>

									Savings Amount {getSortIcon('savingsAmount')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('collectionMonth')}>

									Collection Month {getSortIcon('collectionMonth')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('endDate')}>

									End Date {getSortIcon('endDate')}

								</th>

								<th className="border-bottom" onClick={() => handleSort('status')}>Status {getSortIcon('status')}</th>

								<th className="border-bottom">Action</th>

							</tr>

						</thead>

						<tbody>

							{modifiedData.length === 0 ? (
								<tr>
								  <td colSpan="7" className="text-center">No Record Found</td>
								</tr>
							  ) : (
								modifiedData.map(t => <TableRow key={`data-${t.id}`} {...t} />)
							)}
							

						</tbody>

					</Table>

					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">

						<Nav>

							<Pagination className="mb-2 mb-lg-0">

								<Pagination.Prev onClick={() => onPageChange(prevPage)}>

									Previous

								</Pagination.Prev>



								{items.map((item, index) => (

									<Pagination.Item active={item === currentPage} onClick={() => onPageChange(item)}>{item}</Pagination.Item>

								))}



								<Pagination.Next onClick={() => onPageChange(nextPage)}>

									Next

								</Pagination.Next>

							</Pagination>

						</Nav>

						<small className="fw-bold">

							Showing <b>{totalUsers}</b> out of <b>{totalRecord}</b> entries

						</small>

					</Card.Footer>

				</Card.Body>

			</Card>



			{/* View For Saving Commitments */}

			<React.Fragment>

				<Modal as={Modal.Dialog} centered show={showCommitmentDefault} onHide={handleCloseCommitment}>

					<Modal.Header>

						<Modal.Title className="h6">Saving Commitment</Modal.Title>

						<Button variant="close" aria-label="Close" onClick={handleCloseCommitment} />

					</Modal.Header>



					<Modal.Body>

						<Row>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>SC-00{commitmentData.id ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Record ID :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>{commitmentData.savers_name ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Member Name :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>{commitmentData.collection_month ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Collection Month :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>{commitmentData.amount ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Monthly Savings Amount :</b>

								</Col>

							</Col>

							<Col md={12}>

								<Col md={8} style={{ float: 'right' }}>

									<p>{commitmentData.saving_goal ?? ""}</p>

								</Col>

								<Col md={4}>

									<b>Savings Goal :</b>

								</Col>

							</Col>

						</Row>
						
						
						<Row>
							<Table hover className="user-table align-items-center user-details-table">
								<thead>
									<tr>
										<th className="border-bottom">
											Sr.No.
										</th>
										<th className="border-bottom">
											Payment Month
										</th>
										<th className="border-bottom">
											Payment Year
										</th>
										<th className="border-bottom">
											Payment Date
										</th>
									</tr>
								</thead>
								<tbody>
								  {paymentHistoryData.length === 0 ? (
									<tr>
									  <td colSpan="4" className="text-center">
										No payment yet
									  </td>
									</tr>
								  ) : (
									paymentHistoryData.map((payment, index) => {
									  const formattedDate = new Date(payment.created_at).toLocaleDateString('en-GB');
									  return (
										<tr key={payment.id}>
										  <td>{index + 1}</td>
										  <td>{payment.payment_month_name}</td>
										  <td>{payment.payment_year}</td>
										  <td>{formattedDate}</td>
										</tr>
									  );
									})
								  )}
								</tbody>
							</Table>	
						</Row>
						

					</Modal.Body>



				</Modal>

			</React.Fragment>

			{/*End View For Saving Commitments */}



		</>

	);

};