
import React, { useState, useEffect } from "react"; 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCog, faEnvelopeOpen, faSearch, faSignOutAlt, faUserShield } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { Row, Col, Nav, Form, Image, Navbar, Dropdown, Container, ListGroup, InputGroup } from '@themesberg/react-bootstrap';

import NOTIFICATIONS_DATA from "../data/notifications";
import Profile3 from "../assets/img/team/profilesImage.png";
import { useHistory } from 'react-router-dom';
import { Routes } from "../routes";
import { Link } from 'react-router-dom';
import axios from 'axios';


export default (props) => {
  const [notifications, setNotifications] = useState([]);
  const areNotificationsRead = notifications.reduce((acc, notif) => acc && notif.read, true);

  const markNotificationsAsRead = () => {
    setTimeout(() => {
      setNotifications(notifications.map(n => ({ ...n, read: true })));
    }, 300);
  };
  
  const history = useHistory();
  const storedUser = localStorage.getItem('user');
  
	if (!storedUser) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	  return false;
	}
  
  const userObject = JSON.parse(storedUser);
  const accessToken = userObject ? userObject.access_token : null;
  
	if (!accessToken) {
	  // Redirect to the login URL
	  history.push('/'); // Replace '/login' with the actual URL of your login page
	}

  function truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substr(0, maxLength) + "...";
    }
    return str;
  }
  
  const user_id = userObject.user_id;
  const user_name = userObject.user_name;
  const role_id   = userObject.role_id;
  const formattedMemberId = `M-${user_id.toString().padStart(6, '0')}`;
  
  
	useEffect(()=>{
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/get_notification';  
		
		const formData = new FormData();
	  
		axios.get(apiBaseURL, {
		  params: formData, // Pass formData as query parameters (optional, adjust based on your API endpoint requirements)
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		})
		  .then(response => {
			if (response.data.status === "success") {
			  setNotifications(response.data.data);
			} else {
			  // Handle other response statuses if needed
			}
		  })
		  .catch(error => {
			// Handle errors here
			console.error('Error fetching data:', error);
		  });
		
	},[]);
	
	
	
	useEffect(()=>{
		
		if(user_id && user_id!=""){
			
			const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/users/'+user_id;  
		
			const formDataUsers = new FormData();
			
			formDataUsers.append('id', user_id);
			formDataUsers.append('id', user_id);
		  
			axios.post(apiBaseURL, formDataUsers, {
				  headers: {
					Authorization: `Bearer ${accessToken}`
				  }
				}).then(({ data }) => {
					if(data.status==="success"){
						if(data?.data?.data?.[0]?.is_active == "0"){
							localStorage.removeItem('user');
							history.push('/');
						}
						if(data?.data?.data.length == "0"){
							localStorage.removeItem('user');
							history.push('/');
						}
					}
				}).catch(error => {
					localStorage.removeItem('user');
					history.push('/');
				});
		}
		
	},[]);
	
	
	const handleButtonClick = (collection_month_request_id, id) => {
		
		console.log("collection_month_request_id",collection_month_request_id);
		
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/notification/'+id;  
		
		const formData = new FormData();
	  
		axios.get(apiBaseURL, {
		  params: formData, // Pass formData as query parameters (optional, adjust based on your API endpoint requirements)
		  headers: {
			Authorization: `Bearer ${accessToken}`
		  }
		})
		  .then(response => {
			if (response.data.status === "success") {
				window.location.href = `${process.env.REACT_APP_FRONT_URL}/collection_month_request/${collection_month_request_id}`;
     
				//window.location.reload();
			}
		  })
		  .catch(error => {
			// Handle errors here
			console.error('Error fetching data:', error);
		  });
		  
    };
  

  const Notification = (props) => {
    const { id, link, sender, image, time, message, read = false, collection_month_request_id } = props;
    const readClassName = read ? "" : "text-danger";

    return (
      <ListGroup.Item action onClick={() => handleButtonClick(collection_month_request_id,id)} className="border-bottom border-light">
        <Row className="align-items-center">
          <Col className="col-auto">
            <Image src={Profile3} className="user-avatar lg-avatar rounded-circle" />
          </Col>
          <Col className="ps-0 ms--2">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h4 className="h6 mb-0 text-small">{sender}</h4>
              </div>
              <div className="text-end">
                <small className={readClassName}>{time}</small>
              </div>
            </div>
            <p className="font-small mt-1 mb-0">{message}</p>
          </Col>
        </Row>
      </ListGroup.Item>
    );
  };
  
	const handleLogout = () => {
		localStorage.removeItem('user');
		history.push('/');
	};

  return (
    <Navbar variant="dark" expanded className="ps-0 pe-2 pb-0">
      <Container fluid className="px-0">
		<div class="shadule-for-desk">
			<p class="fw-bold dropdown-item schedul-btn" role="button">
				<Link to={Routes.Membership.path}  class="m-1 btn btn-primary btn-sm"> Membership Agreement </Link>&nbsp;
				<Link to={Routes.Fee.path}  class="m-1 btn btn-primary btn-sm"> Fee Schedule </Link>&nbsp;
				<a href="https://g.co/kgs/yDegUC9" target="_blank"  class="m-1 btn btn-primary btn-sm"> Reviews </a>
			</p>
		</div>
		
        <div className="d-flex justify-content-between w-100 headerMainToggle">
          <div className="d-flex align-items-center toggleICon">
			<div className="toggleIConInner"></div>
		  </div>
		  <div className="headerLogo">
			Savvey Savers Networks
		  </div>
          <Nav className="align-items-center headerRightIcon">
            <Dropdown as={Nav.Item} onToggle={markNotificationsAsRead} align="start" drop="left">
              <Dropdown.Toggle as={Nav.Link} className="text-dark icon-notifications me-lg-3">
                <span className="icon icon-sm">
                  <FontAwesomeIcon icon={faBell} className="bell-shake" /><span className="notificationFlag">{notifications.length}</span>
                  {areNotificationsRead ? null : <span className="icon-badge rounded-circle unread-notifications" />}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="dashboard-dropdown notifications-dropdown dropdown-menu-lg dropdown-menu-center mt-2 py-0">
                <ListGroup className="list-group-flush">
                  <Nav.Link href="#" className="text-center text-primary fw-bold border-bottom border-light py-3">
                    Notifications
                  </Nav.Link>
					
				  <div className="notificationDropdown">
					{notifications.length === 0 ? (
						  <p className="text-center mt-5">No notification available</p>
					 ) : (
						notifications.map(n => <Notification key={`notification-${n.id}`} {...n} />)
					 )}
					
				  </div>

				  {/*
                  <Dropdown.Item className="text-center text-primary fw-bold py-3">
                    View all
                  </Dropdown.Item>
					*/}
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown as={Nav.Item}>
              <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
                <div className="media d-flex align-items-center">
                  <Image src={Profile3} className="user-avatar md-avatar rounded-circle" />
                  <div className="media-body ms-2 text-dark align-items-center d-none d-lg-block">
                    <span className="mb-0 font-small fw-bold">{truncateString(user_name,25)} ({formattedMemberId})</span>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
			  {/*
                <Dropdown.Item className="fw-bold">
				<Link to={Routes.Profile.path}>
                  <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile </Link>
                </Dropdown.Item>
			  */}
			  
			   <Link to={Routes.Profile.path} class="fw-bold dropdown-item memberID" role="button">
					ID :   {formattedMemberId}
			   </Link>
				
				<Link to={Routes.Profile.path} class="fw-bold dropdown-item" role="button">
					<FontAwesomeIcon icon={faUserCircle} className="me-2" /> <Link to={Routes.Profile.path}> My Profile </Link>
				</Link>
				
                 
                <Dropdown.Divider />
				
				{/*
                <Dropdown.Item className="fw-bold">
                <FontAwesomeIcon icon={faSignOutAlt} className="text-primary me-2" /> <a href="javascript:void(0)" onClick={handleLogout} >Logout</a>

                </Dropdown.Item>
				*/}
				
				<Link onClick={handleLogout} class="fw-bold dropdown-item" role="button">
					<FontAwesomeIcon icon={faSignOutAlt} className="me-2" /> <Link onClick={handleLogout}> Logout </Link>
				</Link>
				
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </div>
      </Container>
	  <div class="shadule-for-mobile">
			<p class="fw-bold dropdown-item schedul-btn" role="button">
				<Link to={Routes.Membership.path}  class="m-1 btn btn-primary btn-sm"> Membership Agreement </Link>&nbsp;
				<Link to={Routes.Fee.path}  class="m-1 btn btn-primary btn-sm"> Fee Schedule </Link><br/>
				<a href="https://g.co/kgs/yDegUC9" target="_blank"  class="m-1 btn btn-primary btn-sm"> Reviews </a>
			</p>
		</div>
    </Navbar>
  );
};
