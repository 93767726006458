

import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEnvelope, faUnlockAlt, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Alert, Card, Button, FormCheck, Container, InputGroup, Image } from '@themesberg/react-bootstrap';



import BgImage from "../assets/img/illustrations/login_img.jpg";

import logoImage from "../assets/img/illustrations/logo.png";

import axios from 'axios';

import { useHistory, useParams } from 'react-router-dom';

import Loader from "../components/Loader";

import remove_icon from "../assets/img/illustrations/remove_icon.svg";





export default () => {

	

	const [loading, setLoading] = useState(false);

   const { register_token } = useParams();

	

  const [formData, setFormData] = useState({

	password: '',

	confirmpassword: '',

	register_token:register_token,

  });



  const [errors, setErrors] = useState({

	password: '',

	confirmpassword: '',

  });

  

  const [invalid, setInvalid] = useState(false);

  const [invaliderror, setInvalidError] = useState("");

  const [isChecked, setIsChecked] = useState(false);
  
  const [passwordValid, setIspasswordValid] = useState(true);

  
  
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
		if(!showPassword){
			const timer = setTimeout(() => {
			  setShowPassword(showPassword);
			}, 5000);
		}
	};
	
	const [showPassword1, setShowPassword1] = useState(false);
	const togglePasswordVisibility1 = () => {
		setShowPassword1(!showPassword1);
		if(!showPassword1){
			const timer = setTimeout(() => {
			  setShowPassword1(showPassword1);
			}, 5000);
		}
	};



  const handleInputChange = (e) => {

    const { name, value } = e.target;

    setFormData({

      ...formData,

      [name]: value,

    });

  };

  const history = useHistory();

  



  const handleSubmit = (e) => {  

    e.preventDefault();

    

    let valid = true;
	let passwordvalid = true;

    const newErrors = {

	  password: '',

	  confirmpassword: '',

    };

	

	if(formData.password === ""){

		newErrors.password = 'Password field is required.';

		valid = false;

	}

	

	if(formData.confirmpassword === ""){

		newErrors.confirmpassword = 'Confirm Password field is required.';

		valid = false;

	}

	

	if (!formData.password || formData.password.length < 8) {

      newErrors.password = 'Password must be at least 8 characters long.';

      valid = false;
	  setIspasswordValid(true);
	  passwordvalid = false;

    }

	if (formData.password !== formData.confirmpassword) {

		newErrors.confirmpassword = 'Password and Confirm Password must match.';

		valid = false;

	}

    if (formData.password!= "") {

		let validate_password = validatePassword(formData.password);

		

		if(validate_password == false){

			newErrors.password = 'Password should have a minimum of 10 characters, including one upper and one lower character, one numbers and one special £$#!*& Character';

			valid = false;
			setIspasswordValid(true);
			passwordvalid = false;

		} else {

			newErrors.password = "";
			setIspasswordValid(false);
		    passwordvalid = true;
		}

		// console.log('validate_password',validate_password);
		// return false;
    }



    setErrors(newErrors);

	

    if (valid) {

		setLoading(true);	

		const apiBaseURL = process.env.REACT_APP_API_BASE_URL+'/resetPassword'; 



		axios.post(apiBaseURL, formData, {

		})

		.then(({ data }) => {

			if(data.status==="success"){

				setLoading(false);

				history.push(`/`);

			}

		}).catch(error => {

		});

    }

  };

  

  

	const validatePassword = (password) => {

		//const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[£$#!@*&]).{8,}$/;
		const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[£$#!*&]).{8,}$/;
		return passwordRegex.test(password);

	};



   const divStyle = {

    height: '100%', // Note: Make sure to enclose 100% in quotes

    

    left: 0,

    top: 0,

    overflow: 'hidden', // Note: Make sure to enclose 'hidden' in quotes

    position: 'fixed'   // Note: Make sure to enclose 'fixed' in quotes

  };

  



  

  

  return (

	<>

	{loading && <Loader/>}

    <main>

      <section className="d-flex align-items-center p-0">

        <Container fluid className="p-0">

          <Row className="m-0 user-area-section reset-page" style={ divStyle }>

            <Col md={8} className="p-0"> 

              <div className="form-bg-image">

                <img src={BgImage} alt="Background" className="w-100 h-100" />

              </div>

            </Col>

            <Col md={4} className="bg-white">

              <div className="bg-white p-0 p-lg-4 mg-b">

                <div className="text-center text-md-center mb-4 mt-md-0">

                  <img src={logoImage} alt="Background" style={{ height: '100px' }} />

                  <h5 className="mb-0">Setup Password</h5>

                </div>

                <Form className="mt-4" onSubmit={handleSubmit}>

				

				   <Row>

					

					<Col md={12} className="mb-3">

					  <Form.Group id="password">

					    <Form.Label>New Password</Form.Label>
						<InputGroup>
							<Form.Control type={showPassword ? 'text' : 'password'} name="password" placeholder="New Password" onChange={handleInputChange} value={formData.password} autoComplete="off"/>
							<Button variant="light" onClick={togglePasswordVisibility}>
									{showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
							</Button>
						</InputGroup>
							
					  </Form.Group>

					  {/* <div className="error-message">{errors.password}</div> */}
					  {passwordValid && (
						  <div className="success-message">
							<p>Password should be: </p>
							<p><Image src={remove_icon} width={20} height={20}/> 8 or more characters</p>
							<p><Image src={remove_icon} width={20} height={20}/> At least one upper and one lowercase letter</p>
							<p><Image src={remove_icon} width={20} height={20}/> At least one number</p>
							<p><Image src={remove_icon} width={20} height={20}/> At least one of the following special characters <br/><br/> <span> [ £ $ # ! * & ] </span></p>
						  </div>
						)}

					</Col>



					<Col md={12} className="mb-3">

					  <Form.Group id="username">

					    <Form.Label>Confirm Password</Form.Label>

						<InputGroup>
							<Form.Control type={showPassword1 ? 'text' : 'password'} name="confirmpassword" placeholder="Confirm Password" onChange={handleInputChange} value={formData.confirmpassword} autoComplete="off"/>
							<Button variant="light" onClick={togglePasswordVisibility1}>
									{showPassword1 ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
							</Button>
						</InputGroup>
						
					  </Form.Group>

					  <div className="error-message">{errors.confirmpassword}</div>

					</Col>

				  </Row>

				  

				  

					{invalid && 

					<Alert variant="danger">

						{invaliderror}

					</Alert>

					}

				  

                  <Button variant="primary" type="submit" className="btn btn-secondry">

                    Reset Password

                  </Button>

                </Form>

              </div>

			  <div className="bg-white p-0 p-lg-4 center">

				<span class="mt-4 Copyright">Copyright @ 2025 Savvey Savers Network</span>

			  </div>

            </Col>

          </Row>

        </Container>

      </section>      

    </main>

	</>

  );

};

