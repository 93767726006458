import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleDown, faAngleUp, faEdit, faEllipsisH, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Nav, Card, Button, Table, Form, Alert, FormCheck, Dropdown, Pagination, ButtonGroup, Col, Row, Modal } from '@themesberg/react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Preloader from "../components/Preloader";
import Loader from "../components/Loader";
import { Routes } from "../routes";
import transactions from "../data/transactions";
import axios from 'axios';
import Swal from 'sweetalert2';


const ValueChange = ({ value, suffix }) => {
	const valueIcon = value < 0 ? faAngleDown : faAngleUp;
	const valueTxtColor = value < 0 ? "text-danger" : "text-success";
	return (
		value ? <span className={valueTxtColor}>
			<FontAwesomeIcon icon={valueIcon} />
			<span className="fw-bold ms-1">
				{Math.abs(value)}{suffix}
			</span>
		</span> : "--"
	);
};



export const MemberReportTable = (searchKey) => {

	const [showDefault, setShowDefault] = useState(false);

	const handleClose = () => {
		setShowDefault(false);
	};


	const [data, setData] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [startPage, setStartPage] = useState(1);
	const [error, setError] = useState(null);
	const [endPage, setEndPage] = useState(1);
	const [totalRecord, setTotalRecord] = useState(1);
	const [perPage, setPerPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [search, setSearch] = useState("");
	const [loading, setLoading] = useState(true);
	const [pageLoad, setPageLoad] = useState(true);
	const [currentSort, setCurrentSort] = useState("id");
	const [currentDirection, setCurrentDirection] = useState("desc");
	const [userData, setUserData] = useState([]);
	const [showdetailDefault, setShowDetailDefault] = useState(false);
	const handleDetailsClose = () => setShowDetailDefault(false);
	const [deactiveReason, setDeactiveReason] = useState([]);
	const storedUser = localStorage.getItem('user');
	const userObject = JSON.parse(storedUser);
	const accessToken = userObject.access_token;
	const user_id = userObject.user_id;
	const role_id = userObject.role_id;
	const role_idd = userObject.role_id;
	const setup_admin_permission = userObject.setup_admin_permission;
	const invite_user_permission = userObject.invite_user_permission;
	const suspend_user_permission = userObject.suspend_user_permission;
	const delete_user_permission = userObject.delete_user_permission;
	const [countries, setCountry] = useState(false);
	const [is_active, setIsActive] = useState("");

	{/*Sorting*/ }

	const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });

	const btnWidth = {
		width: '125px'
	};


	function truncateString(str, maxLength) {
		if (str.length > maxLength) {
			return str.substr(0, maxLength) + "...";
		}
		return str;
	}
	
	
	const handleInputSavingYear = (e) => {
		const { name, value } = e.target;
		setIsActive(value);
	};
	
	
	 const exportData = () => {
		 
		  const imageUrl = process.env.REACT_APP_API_BASE_URL + "/export-member?is_active="+is_active;
		  
		  console.log("imageUrl",imageUrl);
		 
		  // Create an anchor element
		  const link = document.createElement('a');
		  link.href = imageUrl;
		  link.rel = 'noopener noreferrer'; // Security best practice
		  document.body.appendChild(link);
		  link.click();
		  document.body.removeChild(link);
	  };



	const fetchData = async page => {
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users';

		const formData = new FormData();

		if (typeof page !== 'undefined') {
		  formData.append('page', page);
		} else {
		  formData.append('page', currentPage);
		}

		formData.append('search', search);
		formData.append('sortBy', currentSort);
		formData.append('orderBy', currentDirection);
		formData.append('is_active', is_active);

		if (role_id != 1) {
			formData.append('user_id', user_id);
		} else {
			formData.append('user_id', "");
		}

		setLoading(true);

		axios.post(apiBaseURL, formData, {
			headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
			}
		}).then(({ data }) => {
				if (data.status === "success") {
					setData(data.data.data);
					setCurrentPage(data.data.current_page);
					setTotalRecord(data.data.total);
					setPerPage(data.data.per_page);
					setStartPage(data.data.per_page);
					setEndPage(data.data.last_page);
					var totalPages = Math.ceil(data.data.total / data.data.per_page);
					setTotalPages(totalPages);
				}
				setLoading(false);

			}).catch(error => {
				setLoading(false);
			});

	};




	const handleSort = (key) => {

		let direction = 'asc';
		if (sortConfig.key === key && sortConfig.direction === 'asc') {
			direction = 'desc';
		}
		setSortConfig({ key, direction });
		setCurrentSort(key);
		setCurrentDirection(direction);
		const apiBaseURL = process.env.REACT_APP_API_BASE_URL + '/users';
		const formData = new FormData();
		formData.append('page', 1);
		formData.append('search', search);
		formData.append('sortBy', key);
		formData.append('orderBy', direction);

		if (role_id != 1) {
			formData.append('user_id', user_id);
		} else {
			formData.append('user_id', "");
		}

		setLoading(true);

		axios.post(apiBaseURL, formData, {
			headers: {
				Authorization: `Bearer ${accessToken}` // Include the access token in the Authorization header
			}
		}).then(({ data }) => {
				if (data.status === "success") {
					setData(data.data.data);
					setCurrentPage(data.data.current_page);
					setTotalRecord(data.data.total);
					setPerPage(data.data.per_page);
					setStartPage(data.data.per_page);
					setEndPage(data.data.last_page);
					var totalPages = Math.ceil(data.data.total / data.data.per_page);
					setTotalPages(totalPages);

				}
				setLoading(false);
			}).catch(error => {
				setLoading(false);
			});

	};
	
	const getSortIcon = (columnKey) => {
		if (sortConfig.key === columnKey) {
			return sortConfig.direction === 'asc' ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
		}
		return <FontAwesomeIcon icon={faSort} />;
	};




	useEffect(() => {
		setSearch(searchKey?.data);

	}, [searchKey?.data]);

	useEffect(() => {
		fetchData(currentPage);
	}, [search]);
	
	useEffect(() => {
		fetchData();
	}, [is_active]);


	const handlePageChange = page => {
		setCurrentPage(page);
	};



	const onPageChange = (pageNo) => {
		setCurrentPage(pageNo);
		fetchData(pageNo);
	};



	if (loading) {
	}



	const items = [];
	for (let i = 0; i < totalPages; i++) {
		items.push(i + 1);
	}

	const totalUsers = data.length;
	const srStart = (currentPage - 1) * perPage;


	const modifiedData = data.map((t, index) => ({
		...t,
		serialNumber: srStart + index + 1 // Assuming the serial number starts from 1
	}));


	const prevPage = currentPage > 1 ? currentPage - 1 : 1;
	const nextPage = currentPage < totalPages ? currentPage + 1 : totalPages;


	const TableRow = (props) => {

		const { id, name, email, phone_number, serialNumber, role_id, created_at, is_saving_commitment, is_invited, is_active, last_login_time, invited_by_user} = props;

		const storedUser = localStorage.getItem('user');

		const userObject = JSON.parse(storedUser);

		const accessToken = userObject.access_token;

		const history = useHistory();

		// Convert created_at to a Date object
		const timezone = 'Europe/London'; // Replace with your desired timezone

		const createdAtDate = new Date(created_at);
		const options = {
			timeZone: timezone,
			day: '2-digit',   // Two-digit day
			month: '2-digit', // Two-digit month
			year: '2-digit',  // Two-digit year
			hour: '2-digit',  // Two-digit hour
			minute: '2-digit', // Two-digit minute
			second: '2-digit', // Two-digit second
			hour12: true       // Use 12-hour format with AM/PM
		};

		// Format created_at as DD-MM-YY HH:mm:ss in the specified timezone
		const formattedCreatedAt = createdAtDate.toLocaleString('en-GB', options);

		const formattedLastLoginTime = last_login_time ? new Date(last_login_time).toLocaleString('en-GB', options) : 'N/A';
		
		const invited_by_user_name = invited_by_user?.name;

		return (
			<tr>
				<td>
					<span className="fw-normal">
						{`M-${id.toString().padStart(6, '0')}`}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						<a href={`users?user=${id}`}>
							{truncateString(name, 25)}
						</a>
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{email}
					</span>
				</td>

				<td>
					<span className="fw-normal">
						{phone_number}
					</span>
				</td>

				{role_idd !== 0 &&
					<td>
						<span className="fw-normal">
							{role_id !== 1 ? 'Savers' : 'Admin'}
						</span>
					</td>
				}

				<td>
					<span className="fw-normal">
						{formattedCreatedAt}
					</span>
				</td>
				
				<td>
					<span className="fw-normal">
						{is_active == "1" ? 'Yes' : 'No'}
					</span>
				</td>
				
				<td>
					<span className="fw-normal">
						{formattedLastLoginTime}
					</span>
				</td>
				<td>
					<span className="fw-normal">
						{invited_by_user_name}
					</span>
				</td>
			</tr>
		);

	};

	return (
		<>
			{loading && <Preloader show={true} />}
			
			
			<Row className="d-flex justify-content-between align-items-center mb-3">
			  <Col xs={8} md={6} lg={3} xl={4}>
				<Form.Select name="is_active" onChange={handleInputSavingYear}>
					<option value="">Choose Active/Inactive User</option>
					<option value="1">Active</option>
					<option value="0">Inactive</option>
				</Form.Select>
			  </Col>
			  <Col xs={4} md={2} xl={4} className="ps-md-0 text-end">
				<ButtonGroup>
					<Button variant="primary" className="m-1" size="sm" onClick={exportData}>Export</Button>
				</ButtonGroup>
			  </Col>
			</Row>

			<Card border="light" className="table-wrapper table-responsive shadow-sm">
				<Card.Body className="pt-0">
					<Table hover className="user-table align-items-center">
						<thead>
							<tr>
								<th className="border-bottom notsort" onClick={() => handleSort('id')}> {role_id !== 1 ? 'Invitation ID' : 'Invitation ID'} {getSortIcon('id')}
								</th>
								<th className="border-bottom" onClick={() => handleSort('name')}>Name {getSortIcon('name')}</th>
								<th className="border-bottom" onClick={() => handleSort('email')}>Email {getSortIcon('email')}</th>
								<th className="border-bottom" onClick={() => handleSort('phone_number')}>Phone Number {getSortIcon('phone_number')}</th>
								{role_idd !== 0 &&
									<th className="border-bottom" onClick={() => handleSort('role_id')}>Role {getSortIcon('role_id')}</th>
								}
								<th className="border-bottom" onClick={() => handleSort('created_at')}>Created On {getSortIcon('created_at')}</th>
								<th className="border-bottom" onClick={() => handleSort('is_active')}>Is Active {getSortIcon('is_active')}</th>
								<th className="border-bottom" onClick={() => handleSort('last_login_time')}>Last logged {getSortIcon('last_login_time')}</th>
								<th className="border-bottom">Invited By</th>
							</tr>
						</thead>

						<tbody>
							{modifiedData.length === 0 ? (
							<tr>
								  <td colSpan="9" className="text-center">No Record Found</td>
								</tr>
							 ) : (
								modifiedData.map(t => <TableRow key={`data-${t.id}`} {...t} />)
							 )}
						</tbody>

					</Table>

					<Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-between">
						<Nav>
							<Pagination className="mb-2 mb-lg-0">
								<Pagination.Prev onClick={() => onPageChange(prevPage)}>
									Previous
								</Pagination.Prev>


								{items.map((item, index) => (
									<Pagination.Item active={item === currentPage} onClick={() => onPageChange(item)}>{item}</Pagination.Item>
								))}


								<Pagination.Next onClick={() => onPageChange(nextPage)}>
									Next
								</Pagination.Next>
								
							</Pagination>
						</Nav>

						<small className="fw-bold">
							Showing <b>{totalUsers}</b> out of <b>{totalRecord}</b> entries
						</small>
					</Card.Footer>
				
				</Card.Body>

			</Card>


		</>

	);

};